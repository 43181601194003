var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-buildings animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('transition',{attrs:{"name":"slide-down"}},[(_vm.isFilterActive)?_c('div',{staticClass:"card filter p-2 mb-1"},[_c('div',{staticClass:"row"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Name","name":"buildingName","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData()}},model:{value:(_vm.filter['name[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'name[partial]', $$v)},expression:"filter['name[partial]']"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Official Name","name":"buildingOfficialName","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData()}},model:{value:(_vm.filter['officialName[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'officialName[partial]', $$v)},expression:"filter['officialName[partial]']"}})],1),_c('div',{staticClass:"row justify-content-end mt-2 px-2"},[_c('button',{staticClass:"btn mr-1",on:{"click":_vm.clearFilter}},[_vm._v("Clear")]),_c('button',{staticClass:"btn main",on:{"click":_vm.filterData}},[_vm._v("Filter")])])]):_vm._e()]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.buildingsTableColumns,"rows":_vm.buildingsTableData,"totalRows":_vm.buildingsTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn mr-1",on:{"click":function($event){return _vm.toEditBuilding(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('button',{staticClass:"btn danger",on:{"click":function($event){return _vm.deleteBuildings(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.toAddBuilding}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }