<template>
  <div class="manage-buildings animatedBox">
    <div class="container fluid">
      <!-- ===================== Filter ===================== -->
      <transition name="slide-down">
        <div v-if="isFilterActive" class="card filter p-2 mb-1">
          <div class="row">
            <fd-input
              v-model="filter['name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="buildingName"
              type="text"
              @keyup.native.enter="filterData()"
            ></fd-input>
            <fd-input
              v-model="filter['officialName[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Official Name"
              name="buildingOfficialName"
              type="text"
              @keyup.native.enter="filterData()"
            ></fd-input>
            <!-- <fd-input
              v-model="filter['parentBuilding:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Parent Building"
              name="buildingParentBuilding"
              type="text"
            ></fd-input>
            <fd-input
              v-model="filter['street:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Street"
              name="buildingStreet"
              type="text"
            ></fd-input>
            <fd-input
              v-model="filter['area:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Area"
              name="buildingArea"
              type="text"
            ></fd-input>
            <fd-input
              v-model="filter['city:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="City"
              name="buildingCity"
              type="text"
            ></fd-input>
            <fd-input
              v-model="filter['state:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="State"
              name="buildingState"
              type="text"
            ></fd-input>
            <fd-input
              v-model="filter['country:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Country"
              name="buildingCountry"
              type="text"
            ></fd-input> -->
          </div>
          <div class="row justify-content-end mt-2 px-2">
            <button class="btn mr-1" @click="clearFilter">Clear</button>
            <button class="btn main" @click="filterData">Filter</button>
          </div>
        </div>
      </transition>
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="buildingsTableColumns"
          :rows="buildingsTableData"
          :totalRows="buildingsTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddBuilding">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button class="btn mr-1" @click="toEditBuilding(props.row.id)">
                <i class="fas fa-pen"></i>
              </button>
              <button class="btn danger" @click="deleteBuildings(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-buildings",
  mixins: [vueGoodTable],
  components: {},
  data() {
    return {
      buildingsTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "150px"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Official Name",
          field: "officialName"
        },
        {
          label: "Parent Building",
          field: "parentBuilding.name"
        },
        {
          label: "Street",
          field: "street"
        },
        {
          label: "Area",
          field: "area.name"
        },
        {
          label: "City",
          field: "city.name"
        },
        {
          label: "State",
          field: "state.name"
        },
        {
          label: "Country",
          field: "country.name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      buildingsTableData: [],
      buildingsTablePagination: {},
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        "officialName[partial]": "",
        "parentBuilding[partial]": "",
        "street:name[partial]": "",
        "area:name[partial]": "",
        "city:name[partial]": "",
        "state:name[partial]": "",
        "country:name[partial]": ""
      }
    };
  },
  mounted() {
    this.getAllBuildings({ limit: this.tableParams.perPage });
  },
  methods: {
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;

      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllBuildings();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => null);
      this.getAllBuildings();
    },
    // ========================= Table related methods =========================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllBuildings();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllBuildings();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddBuilding() {
      this.$router.push({ name: "ManageBuildingsAdd" });
    },
    toEditBuilding(id) {
      this.$router.push({ name: "ManageBuildingsEdit", params: { id: id } });
    },

    // ============================= API Related ===============================
    async getAllBuildings() {
      this.$store.commit("setIsLoading", true);
      let filteredParam = this.$cleanQueryParam(this.filter);
      let data = await this.$store.dispatch("manageBuildings/getAllBuildings", {
        ...filteredParam,
        page: this.tableParams.page,
        limit: this.tableParams.perPage
      });

      this.buildingsTableData = this._.cloneDeep(data.data);
      this.buildingsTablePagination = this._.cloneDeep(data.meta.pagination);
      this.$store.commit("setIsLoading", false);
    },
    deleteBuildings(id) {
      this.$confirm({
        message: "Are you sure you want to delete this?",
        type: "alert"
      }).then((confirmed) => {
        if (confirmed) {
          this.confirmDeleteBuildings(id);
        }
      });
    },
    async confirmDeleteBuildings(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch("manageBuildings/deleteBuilding", id);
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Building has been deleted successfully."
        });
        this.getAllBuildings();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: " An unexpected error occured. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style>
.manage-buildings {
}
</style>
